import React, { useState, useContext, useRef } from "react";
import ReactQuill from "react-quill";
import {
	Card,
	CardBody,
	Form,
	FormInput,
	ButtonGroup,
	Button,
	Row,
	Col,
	ModalHeader,
} from "shards-react";
import Modal from "../common/Modal";
import useDatabase from "../../hooks/useDatabase";
import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import { AuthContext } from "../../context/Context";
import MediaSelectorDropdown from "./MediaSelectorDropdown";

const Quill = ReactQuill.Quill;
const Font = Quill.import("formats/font");
Font.whitelist = ["Barlow", "Cormorant", "RobotoMono", "BarlowBold", "CormorantBold"];
Quill.register(Font, true);

const modules = {
	toolbar: [
		[{ font: Font.whitelist }],
		[{ size: ["small", false, "large", "huge"] }, { header: [1, 2, 3, 4, 5, 6, false] }],
		["bold", "italic", "underline", "strike"],
		[{ list: "ordered" }, { list: "bullet" }],
		[{ indent: "-1" }, { indent: "+1" }],
		[{ direction: "rtl" }, { align: [] }],
		[{ color: [] }, { background: [] }],
		[{ script: "sub" }, { script: "super" }],
		["formula", "code-block", "blockquote"],
		["clean"],
	],
};

const formats = [
	"font",
	"size",
	"bold",
	"italic",
	"underline",
	"strike",
	"list",
	"bullet",
	"align",
	"color",
	"formula",
	"image",
	"video",
	"background",
	"script",
	"indent",
	"link",
	"header",
	"blockquote",
	"code-block",
	"direction",
];

const Editor = ({
	checkedRooms,
	checkedPublicChannel,
	selectedTags,
	setCheckedRooms,
	setSelectedTags,
}) => {
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [contentLength, setContentLength] = useState(0);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalOpenPublic, setModalOpenPublic] = useState(false);
	const [mediaLink, setMediaLink] = useState("");
	const [media, setMedia] = useState("");
	const quillRef = useRef(null);

	const db = useDatabase();
	const { user } = useContext(AuthContext);
	const userName = user ? user.profile.userName : "";

	const titleChange = ({ target: { value } }) => {
		setTitle(value);
	};

	const mediaLinkChange = ({ target: { value } }) => {
		setMediaLink(value);
	};
	const [mediaType, setMediaType] = useState("image");
	const insertMedia = () => {
		if (!mediaLink) {
			console.error("Media link is empty or invalid");
			return;
		}

		try {
			const quill = quillRef.current.getEditor();
			const cursor = quill.getSelection().index;

			quill.insertEmbed(cursor, mediaType, mediaLink);
		} catch (error) {
			console.error("Error inserting image:", error);
		}
	};

	const editorChange = (content, delta, source, editor) => {
		setContent(content);
		setContentLength(editor.getLength());
	};

	const handleSave = async () => {
		if (contentLength <= 1 || checkedRooms.length < 1) {
			return;
		}

		let writes = 0;
		for (const { key } of checkedRooms) {
			writes++;

			const messageKey = db.saveDocument(
				key,
				{ title, content, userName, mediaLink, tags: selectedTags, media },
				checkedRooms.length,
				writes,
				() => {
					setTitle("");
					setContent("");
					setModalOpen(false);
					setCheckedRooms([]);
					setMediaLink("");
					setSelectedTags([]); // Clear tags after saving
					setMedia("");
				}
			);

			if (messageKey) {
				try {
					const res = await fetch(
						`https://postprocess-dxfmjwi24a-uc.a.run.app/chatUrl?messageId=${messageKey}&channelId=${key}`,
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
							},
						}
					);
					console.log("process res", res);
				} catch (error) {
					console.error("Error fetching post-process URL:", error);
				}
			}
		}
	};

	const handlePublicSave = () => {
		if (contentLength <= 1 || checkedPublicChannel.length < 1) {
			return;
		}

		let writes = 0;
		checkedPublicChannel.forEach(({ channelId }) => {
			writes++;
			db.saveDocument(
				channelId,
				{ title, content, userName, mediaLink, tags: selectedTags },
				checkedPublicChannel.length,
				writes,
				() => {
					setTitle("");
					setContent("");
					setModalOpenPublic(false);
					setMediaLink("");
					setSelectedTags([]); // Clear tags after saving
				}
			);
		});
	};

	const selectedPublicChannel = () => (
		<ul className="list-unstyled list-group overflow-auto">
			{checkedPublicChannel &&
				checkedPublicChannel.map(({ channelName }, index) => (
					<li key={index} className="list-group-item">
						{channelName}
					</li>
				))}
		</ul>
	);

	const selectedRooms = () => (
		<ul className="list-unstyled list-group overflow-auto">
			{checkedRooms &&
				checkedRooms.map(({ title, tags }, index) => (
					<li key={index} className="list-group-item">
						<ModalHeader>{title}</ModalHeader>
						<div>
							{tags && tags.length
								? tags.map((tag) => (
										<div key={tag}>
											<input type="checkbox" checked={selectedTags.includes(tag)} />{" "}
											<span>{tag}</span>
										</div>
									))
								: null}
						</div>
					</li>
				))}
		</ul>
	);

	return (
		<>
			{checkedRooms.length === 0 && (
				<Modal
					modalOpen={modalOpen}
					modalTitle="Please Select a Channel!"
					affirmText="Okay"
					action={() => {
						setModalOpen(false);
					}}
					closeModal={() => setModalOpen(false)}
				>
					<h4 className="text-danger scriber-bold">You must select a channel to post into</h4>
				</Modal>
			)}
			{modalOpen && checkedRooms.length !== 0 && (
				<Modal
					modalOpen={modalOpen}
					modalTitle="Publish To This Channel"
					affirmText="Publish"
					action={handleSave}
					closeModal={() => setModalOpen(false)}
				>
					{selectedRooms()}
				</Modal>
			)}

			{modalOpenPublic && (
				<Modal
					modalOpen={modalOpenPublic}
					modalTitle="Publish to this Channel"
					affirmText="Publish"
					action={handlePublicSave}
					closeModal={() => setModalOpenPublic(false)}
				>
					{selectedPublicChannel()}
				</Modal>
			)}

			<Card small className="rounded-0">
				<CardBody className=" blue-border">
					<Form className="add-new-post">
						<FormInput
							size="lg"
							className="mb-3"
							placeholder="TITLE YOUR POST"
							value={title}
							onChange={titleChange}
						/>
						<Row className="mb-3 no-gutters d-flex">
							<Col className="flex-grow-1">
								<FormInput
									placeholder="Place your inline media link here"
									value={mediaLink}
									onChange={mediaLinkChange}
								/>
							</Col>
							<Col md={2} className="auto d-flex justify-content-end">
								<MediaSelectorDropdown mediaType={mediaType} setMediaType={setMediaType} />
							</Col>
							<Col md={2} className="auto d-flex justify-content-end">
								<Button onClick={insertMedia}>Insert</Button>
							</Col>
						</Row>

						<ReactQuill
							ref={quillRef}
							className="add-new-post__editor mb-1"
							placeholder="STYLE YOUR POST"
							modules={modules}
							formats={formats}
							value={content}
							onChange={editorChange}
						/>
					</Form>
				</CardBody>
			</Card>

			<div className="px-5 mx-5">
				<Row className="justify-content-center">
					<Col className="text-center">
						<ButtonGroup className=" mb-2">
							<Button
								onClick={() => setModalOpen(true)}
								className="rounded-0 blue-banner scriber-bold px-5"
							>
								PUBLISH
							</Button>
						</ButtonGroup>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default Editor;
