import React, { useState, useEffect } from "react";

import { Card, CardBody, CardHeader, Container } from "shards-react";

import { database } from "../../firebase";
import { query, ref as refD, get } from "firebase/database";
import { formatMessageTimestamp } from "../../helpers/formatTimestamp";
import {
	handleDownrankMessages,
	handleHideMessages,
	handlePenalizeMessages,
	handleReplyMessage,
	handleUnhideMessages,
	handleUnpenalizeMessages,
	handleUprankMessages,
} from "./user-post-services";
import useSelectedMessagesStore from "../../store/selectedMessagesStore";
import dots from "../../assets/svg/dot.svg"
import CustomModal from "../common/Modal";
function UserPostMessages({ channelId, channelName }) {
	const [replyText, setReplyText] = useState("");
	const [isReplyTextfieldShown, setIsReplyTextfieldShown] = useState(false);
	const [messages, setMessages] = useState([]);
	const [isFetchingMessages, setIsFetchingMessages] = useState(false);
	const [isHidingMessages, setIsHidingMessages] = useState(false);
	const [isUnHidingMessages, setIsUnHidingMessages] = useState(false);
	const [isPenalizingMessages, setIsPenalizingMessages] = useState(false);
	const [isUnPenalizingMessages, setIsUnPenalizingMessages] = useState(false);
	const [isUpRankingMessages, setIsUpRankingMessages] = useState(false);
	const [isDownRankingMessages, setIsDownRankingMessages] = useState(false);
	const [isReplyingUserMessages, setIsReplyingUserMessages] = useState(false);
	const selectedMessages = useSelectedMessagesStore(
		(state) => state.selectedMessages
	);

	const setSelectedMessages = useSelectedMessagesStore(
		(state) => state.setSelectedMessages
	);
	const [showProfaneMap, setShowProfaneMap] = useState({});

	const toggleProfanityDisplay = (messageId) => {
		setShowProfaneMap((prev) => ({
			...prev,
			[messageId]: !prev[messageId],
		}));
	};

	const handleFetchChannelMessages = async () => {
		try {
			if (isFetchingMessages || !channelId) return;
			setIsFetchingMessages(true);

			const messagesQuery = query(refD(database, `messages/${channelId}`));

			const snapshot = await get(messagesQuery);
			if (snapshot.exists()) {
				const messagesObj = snapshot.val();
				const messagesArr = Object.keys(messagesObj).map((key) => ({
					...messagesObj[key],
					isActionDropdownOpen: false,
					messageId: key,
				}));
				setMessages(messagesArr);
			} else {
				setMessages([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsFetchingMessages(false);
		}
	};

	const addOrRemoveSelectedMessages = (messageId) => {
		setSelectedMessages((prevSelectedMessage) => {
			if (prevSelectedMessage.includes(messageId)) {
				// Remove the username if it's already in the list
				return prevSelectedMessage.filter(
					(prevMessageId) => prevMessageId !== messageId
				);
			} else {
				// Add the username if it's not in the list
				return [...prevSelectedMessage, messageId];
			}
		});
	};

	useEffect(() => {
		if (channelId) {
			handleFetchChannelMessages();
		}
	}, [channelId]);

	return (
		<>
			<Card small className="mb-3 scriber-bold rounded-0 blue-border">
				<CardHeader>
					<h5 className="barlow">{channelName}</h5>
				</CardHeader>
				<CardBody>
					{messages.map((message, index) => {
						return (
							<div key={index} className="messages-container">
								<div className="messages-item">
									<div className="messages-user align-items-center gap-4">
										<h6
											style={{
												fontWeight: 700,
												fontSize: "14px",
												marginBottom: 0,
												marginRight: "20px",
											}}
										>
											{message.sentBy}
										</h6>
									</div>

									<div className="messages-info mt-2">
										<div className="d-flex align-items-center">
											<small className="mr-3">
												{formatMessageTimestamp(message.sentAt)}
											</small>
										</div>
										<div key={index} className="d-flex align-items-center mb-3">
											<label
												className="mb-0 mr-2 channelUsersLabel"
												htmlFor="publicChannelMessages"
											>
												<input
													type="checkbox"
													id="publicChannelMessages"
													name="publicChannelMessages"
													onChange={() => {
														addOrRemoveSelectedMessages(message.messageId);
													}}
													checked={selectedMessages.includes(message.messageId)}
												/>
											</label>
											<p
												style={{
													fontWeight: 400,
													fontSize: "14px",
													marginBottom: "1px",
													overflow: "ellipsis",
													display:"flex",
													justifyContent: 'space-between'
												}}
											>
												{message.messageContent} 
												<span className="userPost-moderation-indicator">
													{message.isHidden && (
														<span style={{ fontWeight: "bold" }}>H</span>
													)}
													{message.isPenalized > 0 && (
														<span style={{ fontWeight: "bold" }}>
															{message.isPenalized == 1 && "P1"}
															{message.isPenalized == 2 && "P2"}
															{message.isPenalized == 3 && "P3"}
														</span>
													)}
													{message.isUpranked > 0 && (
														<span style={{ fontWeight: "bold" }}>
															{message.isUpranked == 1 && "U1"}
															{message.isUpranked == 2 && "U2"}
															{message.isUpranked == 3 && "U3"}
														</span>
													)}
												</span>
												<span className="" style={{width:'5px'}} onClick={() => toggleProfanityDisplay(message.messageId)}>
													<img src={dots} />
												</span>
											</p>
											
										</div>
									</div>
								</div>
								{message?.metadata?.profanity && showProfaneMap[message.messageId] &&
								<div className="my-1 border p-2" style={{}}>
									{Object.entries(message?.metadata?.profanity).map(
										([key, value]) => (
											<div key={key} className="my-1">{key}: {+(Math.round(parseFloat(value) + "e+2")  + "e-2")}</div>
										)
									)}
								</div>}
								
							</div>
						);
					})}
				</CardBody>
			</Card>
			<div className="barlow fixed-bottom-container">
				<Container className="d-flex justify-content-between align-items-center gap-3 p-0">
					<div className="userPost-userActions">
						<button
							className="moderation-button"
							onClick={async () => {
								setIsHidingMessages(true);
								await handleHideMessages(channelId, selectedMessages);
								setIsHidingMessages(false);
								setSelectedMessages([]);
								handleFetchChannelMessages();
							}}
							disabled={isHidingMessages}
						>
							{isHidingMessages ? "Loading" : "Hide"}
						</button>
						<button
							className="moderation-button"
							onClick={async () => {
								setIsUnHidingMessages(true);
								await handleUnhideMessages(channelId, selectedMessages);
								setIsUnHidingMessages(false);
								setSelectedMessages([]);
								handleFetchChannelMessages();
							}}
							disabled={isUnHidingMessages}
						>
							{isUnHidingMessages ? "Loading" : "UnHide"}
						</button>
					</div>

					<div className="userPost-userActions">
						<button
							className="moderation-button"
							onClick={async () => {
								setIsPenalizingMessages(true);
								await handlePenalizeMessages(channelId, selectedMessages);
								setIsPenalizingMessages(false);
								setSelectedMessages([]);
								handleFetchChannelMessages();
							}}
							disabled={isPenalizingMessages}
						>
							{isPenalizingMessages ? "Loading" : "Penalize"}
						</button>
						<button
							className="moderation-button"
							onClick={async () => {
								setIsUnPenalizingMessages(true);
								await handleUnpenalizeMessages(channelId, selectedMessages);
								setIsUnPenalizingMessages(false);
								setSelectedMessages([]);
								handleFetchChannelMessages();
							}}
							disabled={isUpRankingMessages}
						>
							{isUnPenalizingMessages ? "Loading" : "Unpenalize"}
						</button>
					</div>
					<div className="userPost-userActions">
						<button
							className="moderation-button"
							onClick={async () => {
								setIsUpRankingMessages(true);
								await handleUprankMessages(channelId, selectedMessages);
								setIsUpRankingMessages(false);
								setSelectedMessages([]);
								handleFetchChannelMessages();
							}}
							disabled={isUpRankingMessages}
						>
							{isUpRankingMessages ? "Loading" : "Uprank"}
						</button>
						<button
							className="moderation-button"
							onClick={async () => {
								setIsDownRankingMessages(true);
								await handleDownrankMessages(channelId, selectedMessages);
								setIsDownRankingMessages(false);
								setSelectedMessages([]);
								handleFetchChannelMessages();
							}}
							disabled={isDownRankingMessages}
						>
							{isDownRankingMessages ? "Loading" : "Downrank"}
						</button>
					</div>
				</Container>

				<Container className="mt-1 p-0">
					<div className="userPost-userActions">
						<div
							style={{
								position: "relative",
								// width: "100px",
							}}
							onClick={(e) => e.stopPropagation()}
						>
							<button
								className="full-width-button py-2 moderation-button"
								onClick={(e) => {
									setIsReplyingUserMessages(true);
									e.stopPropagation();
									setIsReplyingUserMessages(true);
									setIsReplyTextfieldShown(!isReplyTextfieldShown);
								}}
								style={{ position: "relative" }}
							>
								{isReplyingUserMessages ? "Loading" : "Reply User"}
							</button>
							{isReplyTextfieldShown && (
								<div
									style={{
										position: "absolute",
										top: "-110%",
										left: "0",
									}}
								>
									<input
										type="text"
										onChange={(e) => {
											e.stopPropagation();
											setReplyText(e.target.value);
										}}
										onClick={(e) => {
											e.stopPropagation();
										}}
										value={replyText}
										placeholder="type message..."
									/>
									<button
										onClick={async () => {
											await handleReplyMessage(
												channelId,
												selectedMessages,
												replyText
											);
											setIsReplyingUserMessages(false);
											setIsReplyTextfieldShown(false);
										}}
										disabled={!isReplyingUserMessages}
										style={{ position: "absolute", right: "0", top: "100%" }}
									>
										Send
									</button>
								</div>
							)}
						</div>
					</div>
				</Container>
			</div>

		</>
	);
}

export default UserPostMessages;
