import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Button, Row, Col, Container } from "shards-react";
import axios from "axios";
import { database, firestore } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";

import useDatabase from "../../hooks/useDatabase";
import { AuthContext } from "../../context/Context";
import { onValue, ref } from "firebase/database";
import ReactQuill from "react-quill";

const Quill = ReactQuill.Quill;
const Font = Quill.import("formats/font");
Font.whitelist = ["Barlow", "Cormorant", "RobotoMono", "BarlowBold", "CormorantBold"];
Quill.register(Font, true);

const modules = {
	toolbar: [
		[{ font: Font.whitelist }],
		[{ size: ["small", false, "large", "huge"] }, { header: [1, 2, 3, 4, 5, 6, false] }],
		["bold", "italic", "underline", "strike"],
		[{ list: "ordered" }, { list: "bullet" }],
		[{ indent: "-1" }, { indent: "+1" }],
		[{ direction: "rtl" }, { align: [] }],
		[{ color: [] }, { background: [] }],
		[{ script: "sub" }, { script: "super" }],
		["formula", "code-block", "blockquote"],
		["clean"],
	],
};

const formats = [
	"font",
	"size",
	"bold",
	"italic",
	"underline",
	"strike",
	"list",
	"bullet",
	"align",
	"color",
	"formula",
	"image",
	"video",
	"background",
	"script",
	"indent",
	"link",
	"header",
	"blockquote",
	"code-block",
	"direction",
];

const StreamCard = ({ checkedRooms, setCheckedRooms }) => {
	const [stream, setStream] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [details, setDetails] = useState("");
	const [content, setContent] = useState("");
	const [selectedTags, setSelectedTags] = useState([]); // Ensure selectedTags is initialized as an empty array
	const [streamStatus, setStreamStatus] = useState("unpublished");
	const [pauseButton, setPauseButton] = useState("PAUSE STREAM");

	const { user } = useContext(AuthContext);
	const db = useDatabase();
	const userName = user ? user.profile.userName : "";

	const URL =
		process.env.NODE_ENV === "development"
			? "http://localhost:3006"
			: "https://us-central1-areabox-chat.cloudfunctions.net/admin";

	// Create stream key
	const createStream = async () => {
		setIsLoading(true);
		try {
			const res = await axios.post(
				`${URL}/create-stream`,
				{},
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			);
			await setDoc(doc(firestore, "streams", "current"), res.data);
			setStream({ ...res.data, status: "created" });
			setStreamStatus("unpublished");
		} catch (e) {
			console.error("Failed to create live stream:", e);
		} finally {
			setIsLoading(false);
		}
	};

	const editorChange = (details) => {
		setDetails(details);
	};

	// save everything
	const handleSave = async () => {
		console.log("🚀 ~ file: StreamCard.js:50 ~ checkedRooms:", checkedRooms);
		if (checkedRooms.length < 1 || Object.keys(stream).length === 0) {
			return;
		}
		let writes = 0;
		setStreamStatus("published");
		checkedRooms.forEach(({ key }) => {
			writes++;
			db.saveDocument(
				key,
				{ title, details, stream, content, userName, tags: selectedTags },
				checkedRooms.length,
				writes,
				() => {
					setTitle("");
					setDetails("");
					setContent("");

					setCheckedRooms([]);

					setSelectedTags([]); // Clear tags after saving
				}
			);
		});
	};

	const handlePauseStream = async () => {
		const res = await db.pauseStream(stream);
		if (res) {
			setPauseButton((pauseButton) => {
				return pauseButton === "PAUSE STREAM" ? "RESUME STREAM" : "PAUSE STREAM";
			});
		}
	};

	const handleEndStream = async () => {
		const res = await db.endStream(stream);
		if (res) {
			setStreamStatus("unpublished");
		}
	};

	useEffect(() => {
		if (Object.keys(stream).length > 0 && streamStatus === "published") {
			const streamRef = ref(database, `streams/${stream.id}`);
			if (streamRef) {
				return onValue(streamRef, (snapshot) => {
					const data = snapshot.val();
					if (data.status !== "paused") {
						setPauseButton("PAUSE STREAM");
					}
				});
			}
		}
	}, [stream, pauseButton]);

	return (
		<Row className="stream-card-containr">
			<Col md="12">
				<Card small className="rounded-0 blue-border">
					<Container className="mt-5 mb-3">
						<input
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							placeholder="TITLE YOUR LIVE STREAM"
							className="form-control mb-3 py-3"
						/>
						<ReactQuill
							className="add-details mb-1"
							placeholder="ADD DETAILS TO STREAM"
							modules={modules}
							formats={formats}
							value={details}
							onChange={editorChange}
						/>
					</Container>
					<div className="p-4 border">
						<h5 className="font-weight-bold mb-2 barlow">
							ENTER THE KEY SHOWN BELOW INTO OBS STUDIO
						</h5>
						<p className="text-muted barlow mb-5">
							(Download Open Broadcaster Software <a href="https://obsproject.com/download">here</a>
							)
						</p>

						<section className="border-top border-bottom my-3 py-2">
							<h5 className="font-weight-bold barlow mb-2">
								<span>STREAM KEY:</span> <span>{stream.streamKey || ""}</span>
							</h5>
						</section>

						<div className="text-center">
							<Button
								squared
								onClick={createStream}
								disabled={isLoading}
								className="mb-5 btn-outline-primary blue-btn-outline"
							>
								{isLoading ? (
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
								) : (
									"GENERATE NEW STREAM KEY"
								)}
							</Button>
						</div>
						<section className="mb-4">
							<h6 className="font-weight-bold barlow">Server:</h6>
							<p className="text-muted mb-5">rtmps://global-live.mux.com:443/app</p>
						</section>
						{streamStatus === "published" && (
							<section className="mb-2 flex">
								<Button
									squared
									onClick={handleEndStream}
									className="rounded-0 btn-outline-primary blue-btn-outline px-3"
								>
									END STREAM
								</Button>
								<Button
									squared
									onClick={handlePauseStream}
									className="rounded-0 btn-outline-primary blue-btn-outline px-3"
								>
									{pauseButton}
								</Button>
							</section>
						)}
					</div>
				</Card>
				<Row className="justify-content-center">
					<Col className="text-center">
						<Button
							squared
							onClick={handleSave}
							className="rounded-0 blue-banner scriber-bold px-5"
						>
							PUBLISH
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

StreamCard.propTypes = {
	title: PropTypes.string,
};

StreamCard.defaultProps = {
	title: "Live Stream",
};

export default StreamCard;
