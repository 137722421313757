export default function () {
	return [
		{
			title: "USER PERMISSIONS",
			htmlBefore: '<i class="material-icons">group</i>',
			to: "/manage-users",
		},
		{
			title: "USER MODERATION",
			htmlBefore: '<i class="material-icons">person</i>',
			to: "/users-posts",
		},
		{
			title: "CREATE POST",
			htmlBefore: '<i class="material-icons">note_add</i>',
			to: "/add-new-post",
		},
		{
			title: "LIVE STREAM",
			htmlBefore: '<i class="material-icons">tv</i>',
			to: "/new-livestream",
		},
		{
			title: "MEDIA LIBRARY",
			htmlBefore: '<i class="material-icons">movie</i>',
			to: "/cinema-manager",
		},
		{
			title: "RSS FEEDS",
			htmlBefore: '<i class="material-icons">rss_feed</i>',
			to: "/get-feed",
		},
		{
			title: "AI AGENTS",
			htmlBefore: '<i class="material-icons"></i>',
			to: "/ai-agents",
		},
		{
			title: "@ AREABOI",
			htmlBefore: '<i class="material-icons"></i>',
			to: "/areaboi",
		},
		{
			title: "CAMPAIGNS",
			htmlBefore: '<i class="material-icons"></i>',
			to: "/campaigns",
		},
	];
}
