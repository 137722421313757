// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import AddNewPost from "./views/CreatePost";
import CinemaManager from "./views/CinemaManager";
import LiveStream from "./views/LiveStream";
import Restrict from "./views/Restrict";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import UserManagement from "./views/UserManagement";
import Feed from "./views/Feed";
import * as ROLES from "./roles"; // Import your roles
import UserProfile from "./views/UserProfile";
import UserModeration from "./views/UserModeration";
import Empty from "./views/Empty";

export default [
	{
		path: "/manage-users",
		layout: DefaultLayout,
		component: UserManagement,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/restrict",
		layout: DefaultLayout,
		component: Restrict,
		public: false,
	},
	{
		path: "/blog-overview",
		layout: DefaultLayout,
		component: BlogOverview,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/user-profile",
		layout: DefaultLayout,
		component: UserProfile,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/add-new-post",
		layout: DefaultLayout,
		component: AddNewPost,
		public: false,
		allowedRoles: [
			ROLES.GLOBALSUPERADMIN,
			ROLES.GLOBALADMIN,
			ROLES.CHANNELMODERATOR,
			ROLES.CHANNELMODERATOR,
		],
	},
	{
		path: "/cinema-manager",
		layout: DefaultLayout,
		component: CinemaManager,
		public: false,
		// allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN]
	},
	{
		path: "/new-livestream",
		layout: DefaultLayout,
		component: LiveStream,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/get-feed",
		layout: DefaultLayout,
		component: Feed,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/ai-agents",
		layout: DefaultLayout,
		component: Empty,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/areaboi",
		layout: DefaultLayout,
		component: Empty,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/campaigns",
		layout: DefaultLayout,
		component: Empty,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	// {
	//   path: "/errors",
	//   layout: DefaultLayout,
	//   component: Errors,
	//   public: true
	// },
	{
		path: "/components-overview",
		layout: DefaultLayout,
		component: ComponentsOverview,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/tables",
		layout: DefaultLayout,
		component: Tables,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/blog-posts",
		layout: DefaultLayout,
		component: BlogPosts,
		public: false,
		allowedRoles: [ROLES.GLOBALSUPERADMIN, ROLES.GLOBALADMIN],
	},
	{
		path: "/users-posts",
		layout: DefaultLayout,
		component: UserModeration,
		public: false,
		allowedRoles: [
			ROLES.GLOBALSUPERADMIN,
			ROLES.GLOBALADMIN,
			ROLES.CHANNELMODERATOR,
			ROLES.CHANNELMODERATOR,
		],
	},
];
