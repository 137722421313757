import React from "react";
import { Container } from "shards-react";

const Empty = () => (
	<Container fluid className="main-content-container px-4 pb-4">
		<div className="page-header py-4">This page is empty. Work will start soon.</div>
	</Container>
);

export default Empty;
